import React, { Component } from 'react'

import './Timer.css'

export default class Timer extends Component {

  render() {
    return (
      <div className="a-countdown" data-gtm-vis-has-fired92799575_82="1" data-gtm-vis-has-fired92799575_83="1">
        <div className="a-countdown__inner" data-gtm-vis-has-fired92799575_82="1" data-gtm-vis-has-fired92799575_83="1">
          <div className="a-clock flip-clock-wrapper" data-gtm-vis-has-fired92799575_82="1" data-gtm-vis-has-fired92799575_83="1">
            <span className="flip-clock-divider days">
              <span className="flip-clock-label">дней</span>
            </span>
            <ul className="flip ">
              <li className="flip-clock-before">
                <a href="/">
                  <div className="up">
                    <div className="shadow"></div>
                    <div className="inn">1</div>
                  </div>
                  <div className="down">
                    <div className="shadow"></div>
                    <div className="inn">1</div>
                  </div>
                </a>
              </li>
              <li className="flip-clock-active">
                <a href="/">
                  <div className="up">
                  <div className="shadow"></div>
                  <div className="inn">2</div>
                  </div>
                  <div className="down">
                  <div className="shadow"></div>
                  <div className="inn">2</div>
                  </div>
                </a>
              </li>
            </ul>
            <ul className="flip ">
              <li className="flip-clock-before">
              <a href="/">
                <div className="up">
                  <div className="shadow"></div>
                  <div className="inn">1</div>
                </div>
                <div className="down">
                  <div className="shadow"></div>
                  <div className="inn">1</div>
                </div>
              </a>
              </li>
              <li className="flip-clock-active">
                <a href="/">
                  <div className="up">
                    <div className="shadow"></div>
                    <div className="inn">2</div>
                  </div>
                  <div className="down">
                    <div className="shadow"></div>
                    <div className="inn">2</div>
                  </div>
                </a>
              </li>
            </ul>
            <ul className="flip ">
              <li className="flip-clock-before">
                <a href="/">
                  <div className="up">
                    <div className="shadow"></div>
                    <div className="inn">2</div>
                  </div>
                  <div className="down">
                    <div className="shadow"></div>
                    <div className="inn">2</div>
                  </div>
                </a>
              </li>
              <li className="flip-clock-active">
                <a href="/">
                  <div className="up">
                    <div className="shadow"></div>
                    <div className="inn">3</div>
                  </div>
                  <div className="down">
                    <div className="shadow"></div>
                    <div className="inn">3</div>
                  </div>
                </a>
              </li>
            </ul>
            <span className="flip-clock-divider hours">
              <span className="flip-clock-label">часов</span>
              <span className="flip-clock-dot top"></span>
              <span className="flip-clock-dot bottom"></span>
            </span>
            <ul className="flip ">
              <li className="flip-clock-before">
                <a href="/">
                  <div className="up">
                    <div className="shadow"></div>
                    <div className="inn">9</div>
                  </div>
                  <div className="down">
                    <div className="shadow"></div>
                    <div className="inn">9</div>
                  </div>
                </a>
              </li>
              <li className="flip-clock-active">
                <a href="/">
                  <div className="up">
                    <div className="shadow"></div>
                    <div className="inn">0</div>
                  </div>
                  <div className="down">
                    <div className="shadow"></div>
                    <div className="inn">0</div>
                  </div>
                </a>
              </li>
            </ul>
            <ul className="flip ">
              <li className="flip-clock-before">
                <a href="/">
                  <div className="up">
                    <div className="shadow"></div>
                    <div className="inn">1</div>
                  </div>
                  <div className="down">
                    <div className="shadow"></div>
                    <div className="inn">1</div>
                  </div>
                </a>
              </li>
              <li className="flip-clock-active">
                <a href="/">
                  <div className="up">
                    <div className="shadow"></div>
                    <div className="inn">1</div>
                  </div>
                  <div className="down">
                    <div className="shadow"></div>
                    <div className="inn">1</div>
                  </div>
                </a>
              </li>
            </ul>
            <span className="flip-clock-divider minutes">
              <span className="flip-clock-label">минут</span>
              <span className="flip-clock-dot top"></span>
              <span className="flip-clock-dot bottom"></span>
            </span>
            <ul className="flip  play">
              <li className="flip-clock-before">
                <a href="/">
                  <div className="up">
                    <div className="shadow"></div>
                    <div className="inn">5</div>
                  </div>
                  <div className="down">
                    <div className="shadow"></div>
                    <div className="inn">5</div>
                  </div>
                </a>
              </li>
              <li className="flip-clock-active">
                <a href="/">
                  <div className="up">
                    <div className="shadow"></div>
                    <div className="inn">4</div>
                  </div>
                  <div className="down">
                    <div className="shadow"></div>
                    <div className="inn">4</div>
                  </div>
                </a>
              </li>
            </ul>
            <ul className="flip  play">
              <li className="flip-clock-before">
                <a href="/">
                  <div className="up">
                    <div className="shadow"></div>
                    <div className="inn">1</div>
                  </div>
                  <div className="down">
                    <div className="shadow"></div>
                    <div className="inn">1</div>
                  </div>
                </a>
              </li>
              <li className="flip-clock-active">
                <a href="/">
                  <div className="up">
                    <div className="shadow"></div>
                    <div className="inn">0</div>
                  </div>
                  <div className="down">
                    <div className="shadow"></div>
                    <div className="inn">0</div>
                  </div>
                </a>
              </li>
            </ul>
            <span className="flip-clock-divider seconds">
              <span className="flip-clock-label">секунд</span>
              <span className="flip-clock-dot top"></span>
              <span className="flip-clock-dot bottom"></span>
            </span>
            <ul className="flip  play">
              <li className="flip-clock-before">
                <a href="/">
                  <div className="up">
                    <div className="shadow"></div>
                    <div className="inn">3</div>
                  </div>
                  <div className="down">
                    <div className="shadow"></div>
                    <div className="inn">3</div>
                  </div>
                </a>
              </li>
              <li className="flip-clock-active">
                <a href="/">
                  <div className="up">
                    <div className="shadow"></div>
                    <div className="inn">2</div>
                  </div>
                  <div className="down">
                    <div className="shadow"></div>
                    <div className="inn">2</div>
                  </div>
                </a>
              </li>
            </ul>
            <ul className="flip  play">
              <li className="flip-clock-before">
                <a href="/">
                  <div className="up">
                    <div className="shadow"></div>
                    <div className="inn">4</div>
                  </div>
                  <div className="down">
                    <div className="shadow"></div>
                    <div className="inn">4</div>
                  </div>
                </a>
              </li>
              <li className="flip-clock-active">
                <a href="/">
                  <div className="up">
                    <div className="shadow"></div>
                    <div className="inn">3</div>
                  </div>
                  <div className="down">
                    <div className="shadow"></div>
                    <div className="inn">3</div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}
