import React from 'react';
//import ReactDOM from 'react-dom/client';
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import App from './App';

import './assets/stylesheets/index.css'; // style of page
import ErrorNotFound from './components/ErrorNotFound/ErrorNotFound';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "*",
    element: <ErrorNotFound />,
  },
]);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);
