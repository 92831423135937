import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isUndefined } from 'lodash';

import './ButtonBlueLarge.css';


export  class ButtonBlueLarge extends React.PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        gtmTag: PropTypes.string,
        isDisabled: PropTypes.bool,
        onClick: PropTypes.func.isRequired,
    }

    handleClick = () => {
        if (this.gtmTagRef) {
            this.gtmTagRef.click()
        }
        this.props.onClick()
    }

    renderGtmTag() {
        return !isUndefined(this.props.gtmTag) ? (
            <div className={classNames(this.props.gtmTag)} ref={c => (this.gtmTagRef = c)} />
        ) : null
    }

    render() {
        const classNameBase = classNames("ButtonBlueLarge_base", {
            "ButtonBlueLarge_isDisabled": this.props.isDisabled,
        })
        return (
            <React.Fragment>
                {this.renderGtmTag()}
                <button className={classNameBase} onClick={this.props.isDisabled ? null : this.handleClick}>
                    {this.props.children}
                </button>
            </React.Fragment>
        )
    }
}

/*import React from 'react';

import { urls } from '../../../utils/routing';

import './ButtonBlueLarge.css';

export  class ButtonBlueLarge extends React.PureComponent {
    handleClick = () => {
        window.location.href = urls.landing;
    }
    
    render() {
        return (
            <button className="ButtonBlueLarge" onClick={ this.handleClick }>
                <span>На главную страницу</span>
            </button>
        )
    }
}
*/