export const urls = {
    contentPage: '/:slug(.*)/',
    create: '/section-modder/create/',
    details: '/:modId/',
    edit: '/:modId/edit/',
    errorNotFound: '/error-not-found/',
    landing: '/',
    modderSection: '/section-modder/',
    news: '/news/',
    info: '/info/',
    search: '/search/',
    subscriptions: '/followed-mods/',
    eula: '/eula/',
    termsofuse: '/terms-of-use/',
    problemInfo: '/problem/',
    modsRules: '/rules/',
    logout: '/logout/',

    adminSection: '/admin-panel/',
    moderatorSection: '/moderator-panel/',

    prolongate: '/prolongate/',
    wot: 'https://worldoftanks.eu/',
    tanki: 'https://tanki.su',
}

export const footerLinks = {
    eula: {
        href: urls.eula, 
        isExternal: false, 
        title: "\u041F\u0440\u0430\u0432\u0438\u043B\u0430 \u0441\u0430\u0439\u0442\u0430"
    }, 
    termsofuse: {
        href: urls.termsofuse, 
        isExternal: false, 
        title: "\u041F\u0440\u0430\u0432\u0438\u043B\u0430 \u043C\u043E\u0434\u043E\u0432"
    }, 
    problemInfo: {
        href: urls.problemInfo, 
        isExternal: true, 
        title: "\u0426\u0435\u043D\u0442\u0440 \u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0438"
    }, 
    tanki: {
        href: urls.tanki, 
        isExternal: true, 
        title: "\u041E\u0444\u0438\u0446\u0438\u0430\u043B\u044C\u043D\u044B\u0439 \u0441\u0430\u0439\u0442 \u0438\u0433\u0440\u044B \u041C\u0438\u0440 \u0422\u0430\u043D\u043A\u043E\u0432"
    }
}