import React, { Component } from 'react';

import { urls } from '../Footer/routing';

import Main from '../Main/Main';
import { ButtonBlueLarge } from '../ButtonBlueLarge/ButtonBlueLarge';

import styles from './ErrorNotFound.module.css'
import Footer from '../Footer/Footer';

export default class ErrorNotFound extends Component {
    handleClick = () => {
        window.location.href = urls.landing
    }

    render() {
        return (
            <>
            <Main>
                <div className={styles.body}>
                <div className={styles.block}>
                    {/* <div className={styles.error}></div> */}
                    <div className={styles.title}>
                    <span>Страница не найдена</span>
                    </div>
                    <div className={styles.content}>
                    <span>Такой страницы не существует или адрес введён неверно.</span>
                    {/* <span>Перейдите на <a>главную страницу.</a></span> */}
                    </div>
                    <ButtonBlueLarge onClick={this.handleClick}>На главную</ButtonBlueLarge>
                </div>
                </div>
            </Main>
            <Footer />
            </>
        )
    }
}
