import React from 'react';

import Main from '../Main/Main';
import Timer from '../Timer/Timer';
//import Clock from '../Clock/Clock';
//import CountDownClock from '../CountDownClock/CountDownClock';

export default function Wrapper() {
  
  return (
    <Main>
      {/* <Clock /> */}
      {/* <CountDownClock /> */}
      <Timer />
    </Main>
  )
}
