//import { Link } from 'react-router-dom';

// import Nav from './components/Nav/Nav';
import Wrapper from './components/Wrapper/Wrapper';
import Footer from './components/Footer/Footer';

import styles from './App.module.css';

function App() {
  return (
    <div className={styles.base}>
      {/* <div className={classNames(styles.header)}>
        <Nav />
      </div> */}
      <div className={styles.body}>
        <Wrapper />
      </div>
      <div className={styles.footer}>
        <Footer />
      </div>
    </div>
  );
}

export default App;
